"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FILTER_KEY_TO_STATUS = exports.INVOICE_STATUS_MAPPER = void 0;
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var pro_regular_svg_icons_1 = require("@fortawesome/pro-regular-svg-icons");
exports.INVOICE_STATUS_MAPPER = {
    draft: {
        text: 'Draft',
        iconColor: '#B6C5CD',
        icon: pro_light_svg_icons_1.faFileInvoice,
        tooltip: {
            title: 'Draft invoice',
            description: 'A new invoice. The customer hasn’t been enabled for recharging yet. The status won’t change until actioned.',
        },
    },
    onHold: {
        text: 'Agreement',
        iconColor: '#46BAF1',
        icon: pro_regular_svg_icons_1.faBallotCheck,
        tooltip: {
            title: 'Pending agreement acceptance',
            description: 'An email was sent to the client to accept the recharge agreement and to add a payment option.',
        },
    },
    scheduledForSending: {
        text: 'Scheduled',
        iconColor: '#46BAF1',
        icon: pro_light_svg_icons_1.faFileInvoiceDollar,
        tooltip: {
            title: 'Scheduled for sending',
            description: 'This invoice is ready and will be sent on the next billing date. New charges that arrive before the send date will '
                + 'be automatically added to this invoice.',
        },
    },
    sent: {
        text: 'Sent',
        iconColor: '#02AD6B',
        icon: pro_light_svg_icons_1.faMemoCircleCheck,
        tooltip: { title: 'Invoice sent', description: 'The invoice was sent to the client and is pending payment.' },
    },
    paymentInProgress: {
        text: 'Paying',
        iconColor: '#02AD6B',
        icon: pro_light_svg_icons_1.faCircleDollarToSlot,
        tooltip: {
            title: 'Payment in progress',
            description: 'The client is on auto-charge and Stripe is processing the payment of this invoice.',
        },
    },
    paid: {
        text: 'Paid',
        iconColor: '#02AD6B',
        icon: pro_regular_svg_icons_1.faCircleDollar,
        tooltip: { title: 'Invoice paid', description: 'The payment of this invoice was collected successfully by Stripe.' },
    },
    failed: {
        text: 'Payment failed',
        iconColor: '#EC7069',
        icon: pro_regular_svg_icons_1.faCircleXmark,
        tooltip: {
            title: 'Payment attempt failed',
            // description: 'Stripe tried unsuccessfully to collect payment for this invoice. The client was notified by email of the issue.',
            description: 'Stripe tried unsuccessfully to collect payment for this invoice.',
        },
    },
    voided: {
        text: 'Void',
        iconColor: '#EC7069',
        icon: pro_regular_svg_icons_1.faCircleXmark,
        tooltip: { title: 'Invoice voided', description: 'The invoice was voided and no payment was collected.' },
    },
    // cardExpired: { text: 'Card expired', iconColor: '#EC7069', icon: faSubtitlesSlash },
};
exports.FILTER_KEY_TO_STATUS = {
    draft: 'draft',
    scheduled: 'scheduledForSending',
    agreement: 'onHold',
    sent: 'sent',
    paying: 'paymentInProgress',
    paid: 'paid',
    failed: 'failed',
    void: 'voided',
};
