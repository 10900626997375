"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mobileSizeInvoiceDetailsTableConf = exports.getCompressedInvoiceDetailsTableConf = void 0;
var end2end_1 = require("@hudled/end2end");
var tableConf_1 = require("containers/Recharges/Invoices/tableConf");
var constants_1 = require("containers/TableMaker/constants");
var formatters_1 = require("utils/formatters");
var XL = constants_1.CellSize.XL, MD = constants_1.CellSize.MD, SM = constants_1.CellSize.SM;
var getCompressedInvoiceDetailsTableConf = function (hasDiscount) { return ({
    headersMap: {
        description: 'Description',
        listPrice: 'Rate',
        // discountPercent: 'Discount',
    },
    row: { sizing: 'compact' },
    layout: { fixedColumns: 0 },
    extractors: {
        date: function (rI) { var _a, _b, _c; return ((_a = rI.servicePeriod) === null || _a === void 0 ? void 0 : _a.start) && ((_b = rI.servicePeriod) === null || _b === void 0 ? void 0 : _b.end) && (0, end2end_1.getDateRange)(rI.servicePeriod.start, (_c = rI.servicePeriod) === null || _c === void 0 ? void 0 : _c.end); },
        discount: function (item) { return item.discount && item.discount < 0 ? undefined : item.discount; },
        listPrice: function (item) { return item.discount && item.discount < 0 ? undefined : item.listPrice; },
    },
    cells: [
        {
            fields: ['description', 'date'], cell: { width: XL, growCell: true }, truncate: {}, noSort: true,
        },
        hasDiscount && {
            field: 'listPrice', cell: { width: MD }, money: {}, fallback: '-', noSort: true,
        },
        hasDiscount && {
            field: 'discount', cell: { width: MD, textAlign: 'right' }, noSort: true, fallback: '-', renderField: formatters_1.formatPercentPrecise,
        },
        { field: 'amount', cell: { width: MD }, money: {}, noSort: true },
    ].filter(end2end_1.notFalsy),
}); };
exports.getCompressedInvoiceDetailsTableConf = getCompressedInvoiceDetailsTableConf;
exports.mobileSizeInvoiceDetailsTableConf = __assign(__assign(__assign({}, tableConf_1.invoiceDetailsTableConf.row), tableConf_1.invoiceDetailsTableConf.layout), { extractors: {
        details: function (item) {
            var _a, _b, _c;
            return "".concat(((_a = item.servicePeriod) === null || _a === void 0 ? void 0 : _a.start) && ((_b = item.servicePeriod) === null || _b === void 0 ? void 0 : _b.end)
                && (0, end2end_1.getDateRange)(item.servicePeriod.start, (_c = item.servicePeriod) === null || _c === void 0 ? void 0 : _c.end), " ").concat(item.discount && item.discount > 0 ? "| Discount: ".concat((0, formatters_1.formatPercentPrecise)(item.discount)) : '');
        },
    }, cells: [
        {
            cell: { width: XL, growCell: true }, fields: ['description', 'details'], truncate: {}, noSort: true,
        },
        { cell: { width: SM, growCell: true }, field: 'amount', money: {}, noSort: true },
    ] });
