"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var __1 = require("..");
var context_1 = require("../context");
var AddOnsSelect = function (_a) {
    var item = _a.item;
    var _b = (0, react_1.useContext)(context_1.AnalyserFormContext), updateRow = _b.updateRow, availableAddOns = _b.availableAddOns, srvPricePlans = _b.srvPricePlans;
    var getAddOnNiceName = function (addOnName) { var _a, _b, _c; return (_c = (_b = (_a = srvPricePlans === null || srvPricePlans === void 0 ? void 0 : srvPricePlans.addOnsMap) === null || _a === void 0 ? void 0 : _a[addOnName]) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : addOnName; };
    return ((0, jsx_runtime_1.jsx)(__1.PlanRowAutocomplete, { options: availableAddOns === null || availableAddOns === void 0 ? void 0 : availableAddOns.map(function (addOnName) { return ({ value: addOnName, label: getAddOnNiceName(addOnName) }); }), label: "Add On", onChange: function (v) { return updateRow(item.id, __assign(__assign({}, item), { addOn: v === null || v === void 0 ? void 0 : v.value })); }, value: item.addOn ? { value: item.addOn, label: getAddOnNiceName(item.addOn) } : null }));
};
exports.default = AddOnsSelect;
