"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectIsImpersonating = exports.makeSelectLocation = exports.selectIsCtrlShiftPressed = exports.selectIsAltPressed = exports.selectIsCtrlPressed = exports.selectIsShiftPressed = exports.makeSelectAreKeysPressed = exports.selectPressedKeys = exports.selectShowOnlyTeamView = void 0;
var reselect_1 = require("reselect");
var selectShowOnlyTeamView = function (_a) {
    var app = _a.app;
    return !!(app === null || app === void 0 ? void 0 : app.showOnlyMyTeam);
};
exports.selectShowOnlyTeamView = selectShowOnlyTeamView;
var selectPressedKeys = function (_a) {
    var _b;
    var app = _a.app;
    return (_b = app === null || app === void 0 ? void 0 : app.pressedKeys) !== null && _b !== void 0 ? _b : [];
};
exports.selectPressedKeys = selectPressedKeys;
var makeSelectAreKeysPressed = function (keyCodes) { return (0, reselect_1.createSelector)(exports.selectPressedKeys, function (pressedKeys) { return keyCodes.every(function (kC) { return !!(pressedKeys === null || pressedKeys === void 0 ? void 0 : pressedKeys.includes(kC)); }); }); };
exports.makeSelectAreKeysPressed = makeSelectAreKeysPressed;
exports.selectIsShiftPressed = (0, exports.makeSelectAreKeysPressed)([16]);
exports.selectIsCtrlPressed = (0, exports.makeSelectAreKeysPressed)([17]);
exports.selectIsAltPressed = (0, exports.makeSelectAreKeysPressed)([18]);
exports.selectIsCtrlShiftPressed = (0, exports.makeSelectAreKeysPressed)([16, 17]);
var selectRouter = function (_a) {
    var router = _a.router;
    return router;
};
var makeSelectLocation = function () { return (0, reselect_1.createSelector)(selectRouter, function (router) { return router.location; }); };
exports.makeSelectLocation = makeSelectLocation;
var selectIsImpersonating = function (_a) {
    var app = _a.app;
    return !!(app === null || app === void 0 ? void 0 : app.isImpersonating);
};
exports.selectIsImpersonating = selectIsImpersonating;
