"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getClientPrice = exports.makeGetClientPrice = exports.makeGetCostAttribution = exports.getTieredPrice = void 0;
var numbers_1 = require("./numbers");
var getTieredPrice = function (priceDef) { return function (units) {
    var e_1, _a;
    var _b, _c;
    var total = (_c = (_b = priceDef.minimum) === null || _b === void 0 ? void 0 : _b.price) !== null && _c !== void 0 ? _c : 0;
    if (priceDef.minimum && units <= priceDef.minimum.endUnit) {
        return total;
    }
    var unitSize = priceDef.unitSize;
    try {
        for (var _d = __values(priceDef.priceTiers), _e = _d.next(); !_e.done; _e = _d.next()) {
            var priceTier = _e.value;
            if (priceTier.endUnit && priceTier.endUnit < units) {
                total += Math.ceil((priceTier.endUnit - (priceTier.startUnit - 1)) / (unitSize !== null && unitSize !== void 0 ? unitSize : 1)) * priceTier.price;
            }
            else {
                total += Math.ceil((units - (priceTier.startUnit - 1)) / (unitSize !== null && unitSize !== void 0 ? unitSize : 1)) * priceTier.price;
                break;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return total;
}; };
exports.getTieredPrice = getTieredPrice;
var makeGetCostAttribution = function (planDiscountMode, subTotal, totalUnits, totalRows) { return function (units) {
    if (planDiscountMode === 'per_unit') {
        return units / totalUnits * subTotal;
    }
    if (planDiscountMode === 'fixed_price') {
        return subTotal / totalRows;
    }
    return undefined;
}; };
exports.makeGetCostAttribution = makeGetCostAttribution;
// For Dext Billing
var makeGetClientPrice = function (planDiscount, currency) { return function (units) {
    var _a, _b;
    var fixedValue = (_a = planDiscount.fixedValue) === null || _a === void 0 ? void 0 : _a[currency];
    if (!fixedValue) {
        return undefined;
    }
    if (planDiscount.mode === 'per_unit') {
        return Math.ceil((units || 1) / ((_b = planDiscount.unitSize) !== null && _b !== void 0 ? _b : 1)) * fixedValue;
    }
    if (planDiscount.mode === 'fixed_price') {
        return fixedValue;
    }
    return undefined;
}; };
exports.makeGetClientPrice = makeGetClientPrice;
// eslint-disable-next-line complexity
var getClientPrice = function (rechCompVendor, recharge, rechargerCompany, rechCust) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    if (recharge.vendorPlanId && ((_b = (_a = rechCust === null || rechCust === void 0 ? void 0 : rechCust.planPriceOverride) === null || _a === void 0 ? void 0 : _a[recharge.vendorPlanId]) === null || _b === void 0 ? void 0 : _b[(_c = rechargerCompany.rechargesCurrency) !== null && _c !== void 0 ? _c : 'AUD']) !== undefined) {
        return (_e = (_d = rechCust === null || rechCust === void 0 ? void 0 : rechCust.planPriceOverride) === null || _d === void 0 ? void 0 : _d[recharge.vendorPlanId]) === null || _e === void 0 ? void 0 : _e[(_f = rechargerCompany.rechargesCurrency) !== null && _f !== void 0 ? _f : 'AUD'];
    }
    if (rechCompVendor.rechVendorId === 'Dext') {
        var planDiscount = recharge.vendorPlanId ? (_g = rechCompVendor.plansDiscountMap) === null || _g === void 0 ? void 0 : _g[recharge.vendorPlanId] : undefined;
        console.log('planDiscount', planDiscount);
        if (planDiscount) {
            console.log('makeGetClientPrice(planDiscount, recharge.currency)(recharge.unitCount ?? 0)', (0, exports.makeGetClientPrice)(planDiscount, recharge.currency)((_h = recharge.unitCount) !== null && _h !== void 0 ? _h : 0));
            return (0, exports.makeGetClientPrice)(planDiscount, recharge.currency)((_j = recharge.unitCount) !== null && _j !== void 0 ? _j : 0);
        }
    }
    if (rechCompVendor.discountMode === 'per_plan' && recharge.vendorPlanId && rechCompVendor.plansDiscountMap) {
        var planDiscountSettings = (_k = rechCompVendor.plansDiscountMap) === null || _k === void 0 ? void 0 : _k[recharge.vendorPlanId];
        var clientPrice = recharge.amount;
        if (planDiscountSettings === null || planDiscountSettings === void 0 ? void 0 : planDiscountSettings.mode) {
            if (planDiscountSettings.mode === 'per_unit') {
                var perUnitprice = (_l = planDiscountSettings.fixedValue) === null || _l === void 0 ? void 0 : _l[recharge.currency];
                clientPrice = perUnitprice && perUnitprice * ((_m = recharge.unitCount) !== null && _m !== void 0 ? _m : 1);
            }
            if (planDiscountSettings.mode === 'fixed_price') {
                clientPrice = (_p = (_o = planDiscountSettings.fixedValue) === null || _o === void 0 ? void 0 : _o[recharge.currency]) !== null && _p !== void 0 ? _p : planDiscountSettings.value;
            }
            if ((planDiscountSettings === null || planDiscountSettings === void 0 ? void 0 : planDiscountSettings.mode) === 'fixed_discount') {
                clientPrice = recharge.listPrice - ((_q = planDiscountSettings.value) !== null && _q !== void 0 ? _q : 0);
            }
            if ((planDiscountSettings === null || planDiscountSettings === void 0 ? void 0 : planDiscountSettings.mode) === 'percent_discount') {
                clientPrice = planDiscountSettings.value !== undefined
                    ? recharge.listPrice * (1 - planDiscountSettings.value)
                    : undefined;
            }
            if (clientPrice) {
                return (0, numbers_1.roundToCents)(clientPrice);
            }
        }
    }
    if (rechCompVendor.discountMode === 'percent_discount') {
        var discountRate = (_r = rechCompVendor.percentForDiscount) !== null && _r !== void 0 ? _r : 0;
        return (0, numbers_1.roundToCents)(recharge.listPrice * (1 - discountRate));
    }
    if (rechCompVendor.discountMode === 'split') {
        var accFirmSplit = (_t = (_s = rechCust === null || rechCust === void 0 ? void 0 : rechCust.accFirmDiscountSplit) !== null && _s !== void 0 ? _s : rechCompVendor.percentForDiscount) !== null && _t !== void 0 ? _t : 0;
        var originalDiscount = (recharge.listPrice - recharge.amount) / recharge.listPrice;
        var discountRate = originalDiscount * (1 - accFirmSplit);
        return (0, numbers_1.roundToCents)(recharge.listPrice * (1 - discountRate));
    }
    if (rechCompVendor.discountMode === 'percent_profit') {
        return (0, numbers_1.roundToCents)(recharge.listPrice * ((_u = rechCompVendor.percentForDiscount) !== null && _u !== void 0 ? _u : 0) + recharge.amount);
    }
};
exports.getClientPrice = getClientPrice;
