"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var styles_1 = require("@mui/material/styles");
exports.default = (0, styles_1.styled)(material_1.TextField)(function (_a) {
    var theme = _a.theme;
    return ({
        '.MuiFilledInput-root': {
            borderRadius: 10,
        },
        '.MuiFilledInput-root:before': {
            display: 'none',
        },
        '.MuiFilledInput-root:after': {
            display: 'none',
        },
        '.MuiInputBase-input': {
            boxShadow: '0px 3px 8px rgba(0,0,0,0.12)',
            height: 40,
            padding: theme.spacing(0, 4),
            background: theme.palette.common.white,
            borderRadius: 10,
            ':focus-visible': __assign({}, theme.focusVisibleStyles),
        },
    });
});
