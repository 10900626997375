"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
// TODO implement this on components with iconSize, iconColor, etc props
var Icon = function (_a) {
    var icon = _a.icon, _b = _a.size, size = _b === void 0 ? 'sm' : _b, _c = _a.color, color = _c === void 0 ? 'riverstone' : _c;
    return ((0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: icon, size: size, color: color }));
};
exports.default = Icon;
