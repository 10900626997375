"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.COUNTRY_CODES = exports.RECHARGED_CUSTOMER_PUBLIC_FIELDS = exports.RECHARGES_SUB_STEPS = exports.RECHARGES_ENABLE_AUTO_RECHARGES_SUB_STEPS = exports.RECHARGES_SETUP_EMAIL_FWD_SUB_STEPS = exports.RECHARGES_SETUP_STEPS_ADVANCED = exports.RECHARGES_SETUP_STEPS = exports.TRANSACTION_TYPES = exports.DATE_FORMAT = exports.PLAN_FREQUENCY = exports.CURRENCY_CODES = exports.VALID_ACC_ONB_STEPS = exports.VALID_ONB_STEPS = exports.VALID_ACC_USER_ONB_STEPS = exports.VALID_USER_ONB_STEPS = exports.VALID_COMPANY_ONB_STEPS = exports.VALID_COMPANY_TEAM_ONB_STEPS = exports.ONBOARDING_EMAILS = exports.ONBOARDING_REMINDERS = exports.REVIEW_TEAM_STACK_STEPS = exports.TEAM_CREATION_STEPS = exports.TEAM_WELCOME_ONB_STEPS = exports.COMPANY_WELCOME_ONB_STEPS = exports.USER_WELCOME_ONB_STEPS = exports.isSupportedAccPlatform = exports.ACC_PLATFORMS_MODAL = exports.SUPPORTED_ACC_PLATFORMS = exports.ACC_PLATFORMS = exports.HUDLED_EXPECTATIONS = exports.PREVIOUS_SAAS_TRACKING = void 0;
exports.PREVIOUS_SAAS_TRACKING = ['SPREADSHEET', 'COMPANY_WIKI', 'ACCOUNTING_SYSTEM', 'NONE', 'DK', 'OTHER'];
exports.HUDLED_EXPECTATIONS = ['spend', 'track', 'reduce', 'deals', 'feedback', 'licenses', 'usage'];
exports.ACC_PLATFORMS = ['Xero', 'QuickBooks', 'Salesforce', 'MYOB', 'NetSuite', 'Sage', 'Others'];
exports.SUPPORTED_ACC_PLATFORMS = ['Xero', 'QuickBooks', 'Salesforce'];
exports.ACC_PLATFORMS_MODAL = ['Xero', 'QuickBooks', 'Salesforce', 'MYOB', 'NetSuite', 'Sage'];
var isSupportedAccPlatform = function (provider) { return !!provider && exports.SUPPORTED_ACC_PLATFORMS.includes(provider); };
exports.isSupportedAccPlatform = isSupportedAccPlatform;
exports.USER_WELCOME_ONB_STEPS = [
    'USER_INTRO', 'HUDLED_EXPECTATIONS', 'SETUP_TEAM', 'SAAS_QUIZ', 'ACC_CUSTOMERS_INFO', 'ACC_CUSTOMERS_INFO',
];
exports.COMPANY_WELCOME_ONB_STEPS = [
    'COMPANY_INTRO', 'SET_ACCOUNTING', 'CONNECT_ACCOUNTING', 'TRACK_SOFTWARE', 'REQUEST_AUDIT', 'ACC_INVITE_MEMBERS',
];
exports.TEAM_WELCOME_ONB_STEPS = ['TRACK_SOFTWARE_TEAM'];
exports.TEAM_CREATION_STEPS = ['SET_MY_TEAM_BASE', 'CREATE_TEAMS', 'ASSIGN_LEADS'];
exports.REVIEW_TEAM_STACK_STEPS = ['REVIEW_STACK_INTRO'];
exports.ONBOARDING_REMINDERS = [
    'teamCreationReminder',
    'fillInTheGapsReminder',
    // 'reviewYourStackReminder',
    // 'opportunitiesReminder',
    // 'saasSurveyReminder',
    'saasAuditReminder',
    'hudledDealsReminder',
    'activationOnboardingStepReminder',
];
exports.ONBOARDING_EMAILS = __spreadArray(__spreadArray([
    'welcome'
], __read(exports.ONBOARDING_REMINDERS), false), [
    'completedOnboarding',
], false);
exports.VALID_COMPANY_TEAM_ONB_STEPS = ['REVIEW_TEAM'];
exports.VALID_COMPANY_ONB_STEPS = ['TEAMS_CREATION', 'FILL_IN_THE_GAPS', 'REQUEST_SAAS_AUDIT'];
exports.VALID_USER_ONB_STEPS = ['VISIT_DEALS'];
exports.VALID_ACC_USER_ONB_STEPS = ['FIRST_CUSTOMER_CONNECTED', 'INVITE_TEAMMATES', 'ANSWER_FULL_ONBOARDING'];
exports.VALID_ONB_STEPS = { USER: exports.VALID_USER_ONB_STEPS, COMPANY: exports.VALID_COMPANY_ONB_STEPS };
exports.VALID_ACC_ONB_STEPS = { USER: exports.VALID_ACC_USER_ONB_STEPS };
exports.CURRENCY_CODES = [
    'USD', 'CAD', 'EUR', 'AED', 'AFN', 'ALL', 'AMD', 'ARS', 'AUD', 'AZN', 'BAM', 'BDT', 'BGN', 'BHD', 'BIF', 'BND',
    'BOB', 'BRL', 'BWP', 'BYR', 'BZD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP',
    'DZD', 'EEK', 'EGP', 'ERN', 'ETB', 'GBP', 'GEL', 'GHS', 'GNF', 'GTQ', 'HKD', 'HNL', 'HRK', 'HUF', 'IDR', 'ILS',
    'INR', 'IQD', 'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KHR', 'KMF', 'KRW', 'KWD', 'KZT', 'LBP', 'LKR', 'LTL',
    'LVL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MOP', 'MUR', 'MXN', 'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK',
    'NPR', 'NZD', 'OMR', 'PAB', 'PEN', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SDG',
    'SEK', 'SGD', 'SOS', 'SYP', 'THB', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VEF',
    'VND', 'XAF', 'XOF', 'YER', 'ZAR', 'ZMK',
];
exports.PLAN_FREQUENCY = ['Monthly', 'Annual'];
exports.DATE_FORMAT = ['YYYY-MM-DD', 'YYYY/MM/DD', 'DD-MM-YYYY', 'DD/MM/YYYY', 'MM/DD/YYYY', 'MM-DD-YYYY'];
exports.TRANSACTION_TYPES = ['bankTransaction', 'purchase', 'journalTransactionLineItem', 'invoice', 'purchaseInvoice', 'bill'];
exports.RECHARGES_SETUP_STEPS = [
    'CONNECT_ACCOUNTING',
    'UPLOAD_XERO_FILE',
    'MAP_CUST_TO_ORGS',
    'ENABLE_AUTO_RECHARGES',
];
exports.RECHARGES_SETUP_STEPS_ADVANCED = [
    'CONNECT_ACCOUNTING',
    'SETUP_EMAIL_FWD',
    'MAP_CUST_TO_ORGS',
    'CONNECT_CLIENT_XEROS',
    'ENABLE_AUTO_RECHARGES',
];
exports.RECHARGES_SETUP_EMAIL_FWD_SUB_STEPS = [
    'SETUP_FWD_ADDRESS',
    'SEND_XERO_INVOICE_EMAIL',
    'SETUP_GMAIL_AUTO_FWD',
];
exports.RECHARGES_ENABLE_AUTO_RECHARGES_SUB_STEPS = [
    'SET_ACC_CODE',
    'SET_BILLING_METHOD',
    'DEFINE_BILLING_TERMS',
    'SET_EMAIL_HEADERS',
    'SET_AUTO_RECHARGING',
];
exports.RECHARGES_SUB_STEPS = __spreadArray(__spreadArray([], __read(exports.RECHARGES_SETUP_EMAIL_FWD_SUB_STEPS), false), __read(exports.RECHARGES_ENABLE_AUTO_RECHARGES_SUB_STEPS), false);
exports.RECHARGED_CUSTOMER_PUBLIC_FIELDS = [
    'name', 'agreementInfo', 'agreementStatus', 'paymentMethodStatus', 'stripePaymentMethod', 'country', 'address',
];
exports.COUNTRY_CODES = ['AF', 'AX', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BS', 'BH', 'BD',
    'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BA', 'BW', 'BV', 'BR', 'IO', 'BN', 'BG', 'BF', 'BI', 'KH', 'CM', 'CA', 'CV', 'KY', 'CF',
    'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CG', 'CD', 'CK', 'CR', 'CI', 'HR', 'CU', 'CY', 'CZ', 'DK', 'DJ', 'DM', 'DO', 'EC', 'EG', 'SV',
    'GQ', 'ER', 'EE', 'ET', 'FK', 'FO', 'FJ', 'FI', 'FR', 'GF', 'PF', 'TF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD', 'GP', 'GU',
    'GT', 'GG', 'GN', 'GW', 'GY', 'HT', 'HM', 'VA', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IM', 'IL', 'IT', 'JM', 'JP', 'JE',
    'JO', 'KZ', 'KE', 'KI', 'KP', 'KR', 'KW', 'KG', 'LA', 'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MK', 'MG', 'MW', 'MY', 'MV',
    'ML', 'MT', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'MS', 'MA', 'MZ', 'MM', 'NA', 'NR', 'NP', 'NL', 'AN', 'NC', 'NZ',
    'NI', 'NE', 'NG', 'NU', 'NF', 'MP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA', 'RE', 'RO',
    'RU', 'RW', 'SH', 'KN', 'LC', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN', 'CS', 'SC', 'SL', 'SG', 'SK', 'SI', 'SB', 'SO', 'ZA', 'GS', 'ES',
    'LK', 'SD', 'SR', 'SJ', 'SZ', 'SE', 'CH', 'SY', 'TW', 'TJ', 'TZ', 'TH', 'TL', 'TG', 'TK', 'TO', 'TT', 'TN', 'TR', 'TM', 'TC', 'TV', 'UG',
    'UA', 'AE', 'GB', 'US', 'UM', 'UY', 'UZ', 'VU', 'VE', 'VN', 'VG', 'VI', 'WF', 'EH', 'YE', 'ZM', 'ZW'];
