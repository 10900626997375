"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var ActionButtonWrapper = (0, material_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: theme.spacing(10),
        '& button': {
            marginRight: theme.spacing(-3.625),
        },
    });
});
exports.default = ActionButtonWrapper;
