"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTo = exports.stepIsDone = exports.checkTeamCreationStep = exports.checkUserOnbStep = exports.checkTeamOnbStep = exports.checkCompanyOnbStep = void 0;
var checkCompanyOnbStep = function (company, onboardingStep) { var _a; return !!((_a = company === null || company === void 0 ? void 0 : company.completedOnboardingSteps) === null || _a === void 0 ? void 0 : _a.includes(onboardingStep)); };
exports.checkCompanyOnbStep = checkCompanyOnbStep;
var checkTeamOnbStep = function (team, onboardingStep) { var _a; return !!((_a = team === null || team === void 0 ? void 0 : team.completedOnboardingSteps) === null || _a === void 0 ? void 0 : _a.includes(onboardingStep)); };
exports.checkTeamOnbStep = checkTeamOnbStep;
var checkUserOnbStep = function (user, onboardingStep) { var _a; return !!((_a = user === null || user === void 0 ? void 0 : user.completedOnboardingSteps) === null || _a === void 0 ? void 0 : _a.includes(onboardingStep)); };
exports.checkUserOnbStep = checkUserOnbStep;
var checkTeamCreationStep = function (company, creationStep) {
    var _a;
    return (!!((_a = company === null || company === void 0 ? void 0 : company.teamCreationSteps) === null || _a === void 0 ? void 0 : _a.includes(creationStep)));
};
exports.checkTeamCreationStep = checkTeamCreationStep;
var stepIsDone = function (profile, company) { return function (stepName) { return ((0, exports.checkUserOnbStep)(profile, stepName) || (0, exports.checkCompanyOnbStep)(company, stepName)); }; };
exports.stepIsDone = stepIsDone;
var getTo = function (isDone, isDisabled, to, toWhenDone) {
    if (isDisabled)
        return undefined;
    if (isDone && toWhenDone)
        return toWhenDone;
    return to;
};
exports.getTo = getTo;
