"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var faAngleDown_1 = require("@fortawesome/pro-regular-svg-icons/faAngleDown");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var material_1 = require("@mui/material");
var styles_1 = require("@mui/material/styles");
var general_1 = require("utils/general");
var BarHor_1 = require("../Graphs/BarHor");
var StyledAccordionSummary = (0, styles_1.styled)(material_1.AccordionSummary, {
    shouldForwardProp: (0, general_1.omitProps)('edgePosition', 'icon', 'iconSize', 'iconColor', 'showTextOnExpand', 'expandedIconPosition', 'selectTitle', 'hideOverflow'),
})(function (_a) {
    var edgePosition = _a.edgePosition, theme = _a.theme, showTextOnExpand = _a.showTextOnExpand, _b = _a.iconColor, iconColor = _b === void 0 ? 'neutral' : _b, expandedIconPosition = _a.expandedIconPosition, selectTitle = _a.selectTitle, hideOverflow = _a.hideOverflow;
    return (__assign(__assign(__assign(__assign({ padding: theme.spacing(0.5, 2), borderRadius: theme.spacing(1), minHeight: theme.spacing(8) }, showTextOnExpand && {
        '& > .showTextOnExpand, & .showTextOnExpand': {
            opacity: 0,
            transition: 'opacity 0.2s ease-in-out',
        },
    }), edgePosition === 'start' && {
        flexDirection: 'row-reverse',
        position: 'relative',
    }), selectTitle && {
        userSelect: 'text',
    }), { ':hover': __assign({ background: theme.palette.secondary.light }, showTextOnExpand && { '& > .showTextOnExpand, & .showTextOnExpand': { opacity: 1 } }), '.Mui-expanded': __assign({}, showTextOnExpand && { '& > .showTextOnExpand, & .showTextOnExpand': { opacity: 1 } }), '& .MuiAccordionSummary-content': __assign({ margin: 0, width: '100%', '&.Mui-expanded': { margin: 0 } }, hideOverflow && { overflow: 'hidden' }), '&.Mui-disabled .MuiTypography-body1': {
            color: '#83949C',
            opacity: 0.65,
        }, '& .MuiAccordionSummary-expandIconWrapper': __assign(__assign({ svg: { color: (0, BarHor_1.getColor)(theme, iconColor) || theme.palette.grey[600] }, '&.Mui-expanded': {
                transform: "rotate(".concat(expandedIconPosition !== null && expandedIconPosition !== void 0 ? expandedIconPosition : 180, "deg)"),
            } }, showTextOnExpand && { '> .svg-inline--fa': { paddingTop: theme.spacing(2) } }), (edgePosition === 'start' && {
            position: 'absolute',
            left: 4,
            zIndex: 1,
        })), '&.Mui-focusVisible': __assign({ backgroundColor: 'transparent' }, theme.focusVisibleStyles) }));
});
var AccordionSummary = function (_a) {
    var children = _a.children, icon = _a.icon, _b = _a.iconSize, iconSize = _b === void 0 ? 'lg' : _b, _c = _a.edgePosition, edgePosition = _c === void 0 ? 'end' : _c, expandedIconPosition = _a.expandedIconPosition, hideOverflow = _a.hideOverflow, props = __rest(_a, ["children", "icon", "iconSize", "edgePosition", "expandedIconPosition", "hideOverflow"]);
    return ((0, jsx_runtime_1.jsx)(StyledAccordionSummary, __assign({ expandIcon: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: icon || faAngleDown_1.faAngleDown, size: iconSize || 'lg' }), edgePosition: edgePosition, expandedIconPosition: expandedIconPosition, hideOverflow: hideOverflow }, props, { children: children })));
};
exports.default = AccordionSummary;
