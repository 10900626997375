"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectOnboardingProgress = exports.selectSteps = exports.selectFillInTheGapsProgress = exports.selectMonthlyAnnualCandidateSubscriptions = void 0;
var reselect_1 = require("reselect");
var populatedDerived_1 = require("utils/selectors/populatedDerived");
var single_1 = require("utils/selectors/single");
var constants_1 = require("./constants");
var stepsDefinitions_1 = require("./stepsDefinitions");
var utils_1 = require("./utils");
exports.selectMonthlyAnnualCandidateSubscriptions = (0, reselect_1.createSelector)(populatedDerived_1.selectPopulatedServiceStats, function (populatedServiceStatsArray) {
    // const result = { monthlySStats: [], annualSStats: [], partnerOfferSStats: [] };
    var result = { annualSStats: [] };
    populatedServiceStatsArray === null || populatedServiceStatsArray === void 0 ? void 0 : populatedServiceStatsArray.forEach(function (sStat) {
        if (sStat.mightBeYearly) {
            result.annualSStats.push(sStat);
        }
    });
    return result;
});
exports.selectFillInTheGapsProgress = (0, reselect_1.createSelector)(exports.selectMonthlyAnnualCandidateSubscriptions, function (_a) {
    var annualSStats = _a.annualSStats;
    var progress = ({
        annual: {
            total: annualSStats.length,
            done: annualSStats
                .filter(function (sS) { return sS.isMarkedYearly !== undefined && constants_1.ANSWER_OPTIONS.includes(sS.isMarkedYearly); })
                .length,
        },
    });
    return __assign(__assign({}, progress), { summary: progress.annual });
});
exports.selectSteps = (0, reselect_1.createSelector)(single_1.selectProfile, single_1.selectMyCompany, function (profile, company) { return (0, stepsDefinitions_1.getSteps)(profile, company || undefined); });
exports.selectOnboardingProgress = (0, reselect_1.createSelector)(single_1.selectProfile, single_1.selectMyCompany, exports.selectSteps, function (profile, company, steps) { return ({
    totalSteps: steps.length,
    stepsDone: steps.filter((0, utils_1.stepIsDone)(profile, company || undefined)).length,
}); });
