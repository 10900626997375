"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var styles_1 = require("@mui/material/styles");
var validators_1 = require("utils/form/validators");
var PREFIX = 'CheckboxInput';
var classes = {
    border: "".concat(PREFIX, "-border"),
    cb: "".concat(PREFIX, "-cb"),
    default: "".concat(PREFIX, "-default"),
    label: "".concat(PREFIX, "-label"),
};
var StyledCb = (0, styles_1.styled)('div')(function (_a) {
    var _b;
    var _c;
    var theme = _a.theme;
    return (_b = {},
        _b[".".concat(classes.border)] = {
            border: "1px solid ".concat(theme.palette.primary.main),
            borderRadius: 10,
        },
        _b[".".concat(classes.cb)] = {
            padding: theme.spacing(0.5),
        },
        _b[".".concat(classes.default)] = {
            border: '1px solid transparent',
        },
        _b[".".concat(classes.label)] = {
            margin: 0,
            '.Mui-typography': {
                lineHeight: '26px',
            },
        },
        _b['.MuiCheckbox-root'] = {
            color: (_c = theme.palette.neutral) === null || _c === void 0 ? void 0 : _c.contrastText,
            '&.Mui-checked': { color: theme.palette.primary.main },
            padding: theme.spacing(0.25, 1, 0, 0),
        },
        _b);
});
var CheckboxInput = function (_a) {
    var input = _a.input, label = _a.label, meta = _a.meta, _b = _a.hasBorder, hasBorder = _b === void 0 ? true : _b, _c = _a.shake, shake = _c === void 0 ? false : _c, disabled = _a.disabled, color = _a.color;
    var errorToShow = (0, validators_1.getErrorToShow)(meta);
    return ((0, jsx_runtime_1.jsxs)(StyledCb, { children: [(0, jsx_runtime_1.jsxs)(material_1.Box, __assign({ display: "flex", alignItems: "flex-start", className: hasBorder && input.checked ? classes.border : classes.default }, { children: [(0, jsx_runtime_1.jsx)(material_1.Checkbox, __assign({}, input, { checked: input.value, disabled: disabled, id: input.name, color: color })), label && (0, jsx_runtime_1.jsx)("label", __assign({ className: classes.label, htmlFor: input.name }, { children: label }))] })), errorToShow
                ? (0, jsx_runtime_1.jsx)(material_1.FormHelperText, __assign({ error: true, className: shake ? 'shake-animation' : undefined }, { children: errorToShow }))
                : (0, jsx_runtime_1.jsx)(material_1.Box, { height: 18 })] }));
};
exports.default = CheckboxInput;
