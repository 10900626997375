"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsFirstAuthLoading = exports.usePageHasMenu = exports.useIsAuthPage = exports.useLogoutUnauthUser = exports.useTrackPressedKeys = exports.useTrackOpenTabs = exports.useLoadBaseData = exports.useQuickDomainRedirect = void 0;
var end2end_1 = require("@hudled/end2end");
var interfaces_1 = require("@hudled/interfaces");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_redux_firebase_1 = require("react-redux-firebase");
var react_router_1 = require("react-router");
var constants_1 = require("utils/constants");
var hooks_1 = require("utils/hooks");
var logging_1 = require("utils/logging");
var single_1 = require("utils/selectors/single");
var actions_1 = require("./actions");
var useQuickDomainRedirect = function () {
    var _a;
    var match = (0, react_router_1.useRouteMatch)('/oauth-origin/:app');
    var matchRedir = (0, react_router_1.useRouteMatch)('/oauth-redir');
    var location = (0, react_router_1.useLocation)();
    var qParams = (0, hooks_1.useQParams)();
    var redirectUrl = matchRedir ? decodeURIComponent((_a = qParams.oauthUrl) !== null && _a !== void 0 ? _a : '') : undefined;
    if (redirectUrl === null || redirectUrl === void 0 ? void 0 : redirectUrl.match(/^https:\/\/login.xero.com\/identity\/connect\/authorize/)) {
        (0, logging_1.logInfo)('Quick Redirecting');
        window.location.href = redirectUrl;
    }
    if (match === null || match === void 0 ? void 0 : match.params.app) {
        (0, hooks_1.redirectToDomain)(match.params.app, location);
    }
};
exports.useQuickDomainRedirect = useQuickDomainRedirect;
var useLoadBaseData = function (profile) {
    var userCanAdmin = (0, end2end_1.userCan)(interfaces_1.ACCESS_LEVEL.Admin)(profile);
    var collections = [];
    var company = (0, react_redux_1.useSelector)(single_1.selectMyCompany);
    var userCanLoadMfaCols = (0, react_redux_1.useSelector)(single_1.selectUserPassesMfaRequirement);
    if (profile === null || profile === void 0 ? void 0 : profile.companyId) {
        collections.push({ collection: 'companies', where: ['__name__', 'in', [profile.mainCompanyId, profile.companyId].filter(end2end_1.notFalsy)] }, { collection: 'recharger_companies', where: ['__name__', 'in', [profile.mainCompanyId, profile.companyId].filter(end2end_1.notFalsy)] }, !end2end_1.IS_RECHARGLY && { collection: 'service_stats', where: ['companyId', '==', profile.companyId] }, { collection: 'company_teams', where: ['companyId', '==', profile.companyId] }, 
        // ToDo: where: ['companyIds', 'array-contains', profile.companyId] }
        !end2end_1.IS_RECHARGLY && { collection: 'services' }, {
            collection: 'notifications',
            where: [['userId', '==', profile.id], ['show', '==', true], ['companyId', '==', profile.companyId]],
            orderBy: ['updatedAt', 'desc'],
        }, { collection: 'insight_requests', where: ['companyId', '==', profile.companyId] }, userCanLoadMfaCols && { collection: 'public_users', where: ['companyIds', 'array-contains', profile.companyId] }, userCanAdmin && { collection: 'integration_connections', where: ['companyId', '==', profile.companyId] }, profile.isAdmin && { collection: 'site_info' }, end2end_1.IS_RECHARGLY && (company === null || company === void 0 ? void 0 : company.hasRecharglySubscription) && { collection: 'rechargly_subscriptions', doc: profile.companyId }, end2end_1.IS_RECHARGLY && { collection: 'recharged_vendors' }, end2end_1.IS_RECHARGLY && { collection: 'recharged_company_vendors', where: ['companyId', '==', profile.companyId] }, end2end_1.IS_RECHARGLY && { collection: 'recharge_imports', where: ['companyId', '==', profile.companyId] });
    }
    (0, react_redux_firebase_1.useFirestoreConnect)(collections.filter(end2end_1.notFalsy));
};
exports.useLoadBaseData = useLoadBaseData;
var useTrackOpenTabs = function () { return (0, react_1.useEffect)(function () {
    var openTabsStr = localStorage.openTabs;
    if (!(openTabsStr === null || openTabsStr === void 0 ? void 0 : openTabsStr.includes(constants_1.TAB_ID))) {
        localStorage.openTabs = openTabsStr ? "".concat(openTabsStr, ",").concat(constants_1.TAB_ID) : constants_1.TAB_ID;
    }
}, []); };
exports.useTrackOpenTabs = useTrackOpenTabs;
var useTrackPressedKeys = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var makeSetKeyState = function (isDown) { return function (e) {
        if (e.keyCode >= 16 && e.keyCode <= 18) {
            dispatch((0, actions_1.setPressedKey)(e.keyCode, isDown));
        }
    }; };
    (0, react_1.useEffect)(function () {
        var setKeyPressed = makeSetKeyState(true);
        var setKeyReleased = makeSetKeyState(false);
        window.addEventListener('keydown', setKeyPressed);
        window.addEventListener('keyup', setKeyReleased);
        return function () {
            window.removeEventListener('keydown', setKeyPressed);
            window.removeEventListener('keyup', setKeyReleased);
        };
    }, []);
};
exports.useTrackPressedKeys = useTrackPressedKeys;
var useLogoutUnauthUser = function (profile) {
    var fb = (0, react_redux_firebase_1.useFirebase)();
    var logoutUnauthUser = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    localStorage.openTabs = localStorage.openTabs
                        .split(',')
                        .filter(function (tabId) { return tabId !== constants_1.TAB_ID; })
                        .join(',');
                    if (!((profile === null || profile === void 0 ? void 0 : profile.isUnauthUser) && (!localStorage.openTabs || localStorage.openTabs === constants_1.TAB_ID))) return [3 /*break*/, 2];
                    return [4 /*yield*/, fb.logout()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    react_1.default.useEffect(function () {
        window.addEventListener('beforeunload', logoutUnauthUser);
        return function () { return window.removeEventListener('beforeunload', logoutUnauthUser); };
    }, [profile]);
};
exports.useLogoutUnauthUser = useLogoutUnauthUser;
var useIsAuthPage = function () {
    var isSignUp = (0, react_router_1.useRouteMatch)('/signup');
    var isActivate = (0, react_router_1.useRouteMatch)('/activate/:invId');
    var isPromote = (0, react_router_1.useRouteMatch)('/promote/:invId');
    var isLogIn = (0, react_router_1.useRouteMatch)('/login');
    var isResetPass = (0, react_router_1.useRouteMatch)('/reset');
    return !!(isSignUp || isActivate || isPromote || isLogIn || isResetPass);
};
exports.useIsAuthPage = useIsAuthPage;
var usePageHasMenu = function () {
    var matches = (0, react_router_1.useRouteMatch)([
        '/dashboard', '/recommendations', '/review-services', '/admin', '/settings', '/deals', '/tools', '/saas-audit',
        '/accountant', '/my-stack', '/benchmarks', '/recharges', '/design-system', '/vendors',
    ]);
    var exactMatches = (0, react_router_1.useRouteMatch)({ path: ['/teams', '/onboarding', '/transaction-contacts'], exact: true });
    return !!exactMatches || !!matches;
};
exports.usePageHasMenu = usePageHasMenu;
var useIsFirstAuthLoading = function (authState, profile) {
    var _a = __read(react_1.default.useState(true), 2), isFirstAuthLoading = _a[0], setIsFirstAuthLoading = _a[1];
    var isAuthLoaded = (0, react_redux_firebase_1.isLoaded)(authState) && (0, react_redux_firebase_1.isLoaded)(profile);
    react_1.default.useEffect(function () {
        if (isFirstAuthLoading && isAuthLoaded) {
            setIsFirstAuthLoading(false);
        }
    }, [isAuthLoaded]);
    return isFirstAuthLoading;
};
exports.useIsFirstAuthLoading = useIsFirstAuthLoading;
