"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var styles_1 = require("@mui/material/styles");
var BaseTooltip = function (_a) {
    var className = _a.className, children = _a.children, props = __rest(_a, ["className", "children"]);
    return ((0, jsx_runtime_1.jsx)(material_1.Tooltip, __assign({}, props, { classes: { popper: className } }, { children: children })));
};
var Tooltip = (0, styles_1.styled)(BaseTooltip, { shouldForwardProp: function (p) { return p !== 'variant'; } })(function (_a) {
    var _b;
    var _c = _a.variant, variant = _c === void 0 ? 'dark' : _c, maxWidth = _a.maxWidth, theme = _a.theme;
    return (_b = {},
        _b["& .".concat(material_1.tooltipClasses.tooltip)] = {
            borderRadius: 4,
            maxWidth: maxWidth !== null && maxWidth !== void 0 ? maxWidth : 480,
            lineHeight: '1.2',
            padding: theme.spacing(1, 1.5),
            backgroundColor: variant === 'light' ? theme.palette.common.white : theme.palette.text.secondary,
            color: variant === 'light' ? theme.palette.text.primary : theme.palette.common.white,
            '.MuiTypography-root, strong': { color: variant === 'light' ? theme.palette.text.primary : theme.palette.common.white },
            boxShadow: variant === 'light' ? '0 8px 24px rgba(149, 157, 165, 0.2)' : undefined,
        },
        _b["& :not(.".concat(material_1.tooltipClasses.tooltipArrow, ").").concat(material_1.tooltipClasses.tooltipPlacementBottom)] = {
            marginTop: '8px !important', // TO DO find a way to remove !important
        },
        _b["& .".concat(material_1.tooltipClasses.arrow)] = {
            color: variant === 'light' ? theme.palette.common.white : '#0F100F',
        },
        _b);
});
exports.default = Tooltip;
