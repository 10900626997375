"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkboxStyles = void 0;
var checkboxStyles = function (_a) {
    var _b;
    var theme = _a.theme, ownerState = _a.ownerState;
    return ({
        padding: 0,
        '&.Mui-focusVisible': __assign({ borderRadius: theme.spacing(0.25) }, theme.focusVisibleStyles),
        '&.Mui-checked': { color: (_b = theme.palette[ownerState.color]) === null || _b === void 0 ? void 0 : _b.main },
        // TODO: research checkbox sizes to override for all SVGs, no specific class names
        // this comes from the SIZE_MAPPER in the Checkbox component
        // MuiSvgIcon is the default icon
        '& .MuiSvgIcon-fontSizeSmall': {
            fontSize: theme.typography.body2.fontSize,
            height: theme.spacing(1.75),
            lineHeight: '20px',
        },
        '& .MuiSvgIcon-fontSizeMedium': {
            fontSize: theme.typography.body1.fontSize,
            height: theme.spacing(2),
            lineHeight: '24px',
        },
    });
};
exports.checkboxStyles = checkboxStyles;
