"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.commentsForServiceQuery = exports.serviceComparisonsQuery = exports.reviewsForServiceQuery = exports.recommendationsForServiceQuery = exports.latestRatesQuery = exports.insightRequestsForServiceQuery = exports.transactionsForServiceQuery = exports.transactionsForServiceStatQuery = void 0;
var end2end_1 = require("@hudled/end2end");
var transactionsForServiceStatQuery = function (sStat, dateRange) { return (0, exports.transactionsForServiceQuery)(sStat.serviceId, {
    where: [
        ['companyId', '==', sStat.companyId],
        (dateRange === null || dateRange === void 0 ? void 0 : dateRange.start) && ['date', '>=', dateRange.start],
        (dateRange === null || dateRange === void 0 ? void 0 : dateRange.end) && ['date', '<', dateRange.end],
    ].filter(Boolean),
    orderBy: ((dateRange === null || dateRange === void 0 ? void 0 : dateRange.start) || (dateRange === null || dateRange === void 0 ? void 0 : dateRange.end)) ? ['date', 'desc'] : undefined,
    storeAs: "".concat(sStat.serviceId, "Transactions"),
}); };
exports.transactionsForServiceStatQuery = transactionsForServiceStatQuery;
var transactionsForServiceQuery = function (serviceId, _a) {
    var where = _a.where, query = __rest(_a, ["where"]);
    return (__assign({ collection: 'transactions', where: __spreadArray([['serviceIds', 'array-contains', serviceId]], __read(where || []), false).filter(end2end_1.notFalsy), storeAs: "".concat(serviceId, "Transactions") }, query));
};
exports.transactionsForServiceQuery = transactionsForServiceQuery;
var insightRequestsForServiceQuery = function (serviceId, _a) {
    if (_a === void 0) { _a = {}; }
    var where = _a.where, query = __rest(_a, ["where"]);
    return (__assign({ collection: 'insight_requests', where: __spreadArray([['serviceId', '==', serviceId]], __read(where || []), false).filter(end2end_1.notFalsy), storeAs: "".concat(serviceId, "InsightRequests") }, query));
};
exports.insightRequestsForServiceQuery = insightRequestsForServiceQuery;
var latestRatesQuery = function () { return ({
    collection: 'rates', storeAs: 'latestRates', orderBy: ['date', 'desc'], limit: 1,
}); };
exports.latestRatesQuery = latestRatesQuery;
var recommendationsForServiceQuery = function (sStat) { return ({
    collection: 'recommendations',
    where: [['companyId', '==', sStat.companyId], ['serviceId', '==', sStat.serviceId]],
    storeAs: "".concat(sStat.serviceId, "Recommendations"),
}); };
exports.recommendationsForServiceQuery = recommendationsForServiceQuery;
var reviewsForServiceQuery = function (sStat) { return ({
    collection: 'reviews',
    where: [['companyId', '==', sStat.companyId], ['serviceId', '==', sStat.serviceId]],
    storeAs: "".concat(sStat.serviceId, "Reviews"),
}); };
exports.reviewsForServiceQuery = reviewsForServiceQuery;
var serviceComparisonsQuery = function (service) {
    var _a, _b;
    return ({
        collection: 'services',
        where: ['name', 'in', ((_a = service === null || service === void 0 ? void 0 : service.comparisons) === null || _a === void 0 ? void 0 : _a.length) ? (_b = service === null || service === void 0 ? void 0 : service.comparisons) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
                var name = _a.name;
                return name;
            }) : ['']],
        storeAs: "".concat(service.id, "Comparisons"),
    });
};
exports.serviceComparisonsQuery = serviceComparisonsQuery;
var commentsForServiceQuery = function (serviceId, companyId) { return ({
    collection: 'comments',
    where: [['companyId', '==', companyId], ['serviceId', '==', serviceId]],
    orderBy: ['createdAt', 'asc'],
    storeAs: "".concat(serviceId, "Comments"),
}); };
exports.commentsForServiceQuery = commentsForServiceQuery;
